import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userInfo: {},
    userSignUp: {},
    verifyOtp:{},
    isLoggedIn: false,
    loading: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: ({
        adminLogin: (state) => {
            state.loading = true
        },
        setAdminLogin: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload
        },
        adminForgetPasswodVerifyOtp: (state) => {
            state.loading = true;
        },
        setAdminForgetPasswodVerifyOtp: (state, action) => {
            state.loading = false;
        },
        adminForgetPassword: (state) => {
            state.loading = true;
        },
        setAdminForgetPassword: (state, action) => {
            state.loading = false;
        },
        adminResendOtp: (state) => {
            state.loading = true;
        },
        setAdminResendOtp: (state, action) => {
            state.loading = false;
        },
        adminResetPassword: (state) => {
            state.loading = true;
        },
        setAdminResetPassword: (state, action) => {
            state.loading = false;
        },
        adminLogout: (state) => {
            state.loading = true
        },
        setAdminLogout: (state, action) => {
            state.loading = false
            state.userInfo = {}
            state.userSignUp = {}
            state.isLoggedIn = false
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { adminLogin, setAdminLogin,adminForgetPasswodVerifyOtp,setAdminForgetPasswodVerifyOtp,adminForgetPassword,setAdminForgetPassword,adminResendOtp,setAdminResendOtp,adminResetPassword,setAdminResetPassword, adminLogout, setAdminLogout, onErrorStopLoad } = authSlice.actions

export default authSlice.reducer