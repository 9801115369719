import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setGetAllUsers, setTrafficData} from '../../slices/dashboard';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';


function* getAllUsers(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.DashboardApiPath.GET_ALL_USERS}?page=${action.payload.page}&limit=${action.payload.limit}&sort=${action.payload.sort}&search=${action.payload.search}`,action.payload = action.payload);
    if (resp.status) {
        yield put(setGetAllUsers(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetAllUsers({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* trafficData(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.DashboardApiPath.TRAFFIC_OVERVIEW);
    if (resp.status) {
        yield put(setTrafficData(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setTrafficData({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* dashboardSaga() {
  yield all([
    takeLatest('dashboard/getAllUsers', getAllUsers),
    takeLatest('dashboard/trafficData', trafficData),

  ])
}

export default dashboardSaga;