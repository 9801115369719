import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createHelpPage, getArticleCategory, getSingleDeskItem, updateDeskItem } from '../../../redux/slices/helpDesk';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHelpDeskSelector } from '../../../redux/selector/helpDesk';
import { toast } from "react-toastify"

const EditArticle = () => {


    const [title, setTitle] = useState("")
    const [textData, setTextData] = useState("<p>Enter your text</p>")
    const [articleCategory, setArticleCategory] = useState("")
    const [ckKey, setCkKey] = useState(Math.random());
    const [articleCategoryId, setArticleCategoryId] = useState("")
    const [loadingEditor, setLoadingEditor] = useState(false)
    const [isSuggested, setIsSuggested] = useState(0)
    const [isRecommended, setIsRecommended] = useState(0)
    const [date, setDate] = useState(new Date())
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const handleChange = (content) => {
    //     console.log(content);
    //     // You can perform actions with the edited content
    //   };

    const location = useLocation()
    const parts = location.pathname.split('/');
    const articleId = parseFloat(parts[parts.length - 1]);
    const helpDeskSelector = useHelpDeskSelector()


    // remove html tags from description
    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    // onchange date set date in the state
    const onChangeDateResponse = (date) => {
        setDate(date)
    }

    // checkbox for is recommended and is suggested
    const handleChange = (e, flag) => {
        const val = e.target.checked
        if (flag === "isSuggested") {
            if (val == true) {
                setIsSuggested(1)
            }
            else {
                setIsSuggested(0)
            }
        }
        else if (flag === "isRecommended") {
            if (val == true) {
                setIsRecommended(1)
            }
            else {
                setIsRecommended(0)
            }
        }
    }

    const getArticleCategories = () => {
        let params = {
        }
        dispatch(getArticleCategory({
            ...params, cb(res) {
                if (res.data) {
                    setArticleCategory(res?.data?.payload)
                }
            }
        }))
    }

    const getArticleInfo = (id) => {
        let params = {
            id: id
        }
        dispatch(getSingleDeskItem({
            ...params, cb(res) {
                if (res.data) {
                    const data = res?.data?.payload;
                    setTitle(data?.title)
                    if (data?.date) {
                        // console.log(typeof(moment(data?.date).format('MM/DD/YYYY')), "date received type");
                        setDate(new Date(data?.date))
                    }
                    if (data?.description !== null) {
                        setTextData(data?.description)

                    }
                    // setDate(moment(data?.date).format('MM/DD/YYYY'))
                    setArticleCategoryId(data?.help_category_details?.id)
                    if (data?.is_recommended) {
                        if (data?.is_recommended === 1) {
                            setIsRecommended(1)
                        }
                    }
                    if (data?.is_suggested) {
                        if (data?.is_suggested === 1) {
                            setIsSuggested(1)
                        }

                    }
                }
            }
        }))
    }



    const handleSubmit = (e) => {
        e.preventDefault()
        if (!title || title.trim() === "") {
            toast.error("Please enter title")
            return;
        }
        else if (title.length > 100) {
            toast.error("Article title can not be more than 50 characters long")
            return;
        }
        else if (textData === "") {
            toast.error("Please enter description")
            return;
        }
        else if (articleCategoryId === "") {
            toast.error("Please choose article catgeory")
            return;
        }
        let params = {
            id: articleId,
            title: title,
            date: date,
            description: typeof (textData) === 'object' ? textData.props.children : textData,
            help_category_id: Number(articleCategoryId),
            is_recommended: isRecommended,
            is_suggested: isSuggested
        }
        dispatch(updateDeskItem({
            ...params, cb(res) {
                if (res.data) {
                    navigate("/help-desk")
                }
            }
        }))
    }

    const handleEditorData = (event, editor) => {
        const data = editor.getData();
        setTextData(data)
    };


    useEffect(() => {
        setLoadingEditor(true);

        const timeoutId = setTimeout(() => {
            setLoadingEditor(false);
        }, 1000);

        return () => {
            clearTimeout(timeoutId);
        };
    }, []);



    useEffect(() => {
        if (articleId) {
            getArticleInfo(articleId)
        }
    }, [articleId])

    useEffect(() => {
        getArticleCategories()
    }, [])
 
 
    return (
        <>
            <div className='containerBox adminDashbord'>

                <div className='searchFilter'>
                    <h3 className='headTxtBlueSmall mb-3'>Update Article</h3>
                    <Link to="/help-desk" className='postBtn'>Go Back</Link>
                </div>
                {
                    helpDeskSelector?.loading && loadingEditor ? <div className="row">
                        <div className="col-sm-12 text-center pt-5 pb-5">
                            <div className="spinner-grow text-success" role="status"></div>
                        </div>
                    </div>
                        :
                        <form className='form mt-5' onSubmit={(e) => handleSubmit(e)}>
                            <div className='row'>
                                <div className='col-md-8 mb-4'>
                                    <div className='form-group'>
                                        <label className='customLabel mb-2'>Article Title <span className='mendatoryFields'>*</span></label>
                                        <input name="email" type='text' className='customFormControl' placeholder='' value={title} onChange={(e) => setTitle(e.target.value)} />
                                    </div>
                                </div>
                                <div className='col-md-4 mb-4'>
                                    <label className='customLabel mb-2'>Date <span className='mendatoryFields'>*</span></label>
                                    <div className='form-group'>
                                        <DatePicker
                                            maxDate={new Date()}
                                            className='customFormControl'
                                            selected={date}
                                            onChange={onChangeDateResponse}
                                            // maxDate={moment().toDate()}
                                            dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                            placeholderText="Month Day, Year" // Example placeholder text
                                        />
                                    </div>
                                </div>
                                <div className='col-md-12 mb-4'>
                                    <label className='customLabel mb-2'>Article Answer <span className='mendatoryFields'>*</span></label>
                                    <div className='form-group'>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={textData}
                                            onChange={handleEditorData}
                                            config={{
                                                removePlugins: [
                                                    "EasyImage",
                                                    "ImageUpload",
                                                    "MediaEmbed",
                                                    "Table",
                                                    "table",
                                                    "TableToolbar",
                                                    "image",
                                                ],

                                                toolbarLocation: ["bottom"],
                                                // removeButtons:
                                                //     "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className='col-md-4 mb-4'>
                                    <div className='form-group'>
                                        <label className='customLabel mb-2'>Article Category <span className='mendatoryFields'>*</span></label>
                                        <select className='customSelect w-100' value={articleCategoryId} onChange={(e) => setArticleCategoryId(e.target.value)}>
                                            <option disabled value="">Search By Category</option>
                                            {articleCategory && articleCategory?.map((val, index) => {
                                                return <option value={val?.id} key={index}>{val?.name}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 mb-3">
                                    <div className='d-flex'>
                                        <input type="checkbox" id="isRecommended" className='checkBox me-2'
                                            checked={isRecommended === 1}
                                            value={isRecommended} onChange={(e) => {
                                                handleChange(e, "isRecommended")
                                            }} />
                                        <label htmlFor="isRecommended" className='checkBoxTxt align-items-start align-items-md-center'>
                                            <span>
                                                Is recommended
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group col-md-12 mb-3">
                                    <div className='d-flex'>
                                        <input type="checkbox" id="isSuggested" checked={isSuggested === 1} className='checkBox me-2' value={isSuggested} onChange={(e) => {
                                            handleChange(e, "isSuggested")
                                        }} />
                                        <label htmlFor="isSuggested" className='checkBoxTxt align-items-start align-items-md-center'>
                                            <span>
                                                Is suggested
                                            </span>
                                        </label>


                                    </div>
                                </div>

                                <div className='col-12 text-end mt-5'>
                                    <button className='submitBtn  mt-2 d-inline mt-5' type="submit" >
                                        <span> Submit Connection </span>
                                    </button>
                                </div>
                            </div>
                        </form>
                }
            </div>
        </>
    )
}

export default EditArticle