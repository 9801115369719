const ApiPath = {
  AuthApiPath: {
    LOGIN_ADMIN: "/api/v1/admin/login",
    ADMIN_FORGET_PASSWORD: "/api/v1/admin/forgot/password",
    ADMIN_VERIFY_OTP: "/api/v1/admin/verify/forgot/password/otp",
    ADMIN_RESEND_OTP: "/api/v1/admin/resend/otp",
    LOGOUT_ADMIN: "/api/v1/admin/logout",
    ADMIN_RESET_PASSWORD: "/api/v1/admin/reset/password",
  },
  DashboardApiPath: {
    GET_ALL_USERS: "/api/v1/admin/all/company",
    TRAFFIC_OVERVIEW: "/api/v1/admin/home/screen",
  },
  HelpDeskApiPath: {
    ARTICLE_CATEGORY: "/api/v1/help/category/all",
    CREATE_HELP_PAGE: "/api/v1/help",
    HELP_DESK_LISTING: "/api/v1/help/all",
    DELETE_DESK_ITEM: "/api/v1/help",
    GET_SINGLE_DESK_ITEM: "/api/v1/help",
    UPDATE_DESK_ITEM: "/api/v1/help",
  },

  UserApiPath: {
    DRIVERS_SUPPLIER_DESCRIPTION: "/api/v1/common/description/data",
    BUSINESS_OWNERSHIP_TYPE: "/api/v1/business_ownership_type",
    ATTACHMENT_UPLOAD: "/api/v1/user_photos/upload",
    INDUSTRY_GROUPS: "/api/v1/industries",
    INDUSTRY_SUB_GROUPS: "/api/v1/industries/sub_industries/",
    DIVERSE_SUPPLIER_CERTIFICATION:
      "/api/v1/common/existing/certification/data",
    CREATE_USER: "/api/v1/admin/create/enterprise/user",
    GET_USER: "/api/v1/admin/enterprise/user",
    UPDATE_USER: "/api/v1/admin/update/enterprise/user",
    GET_PLANS: "/api/v1/payment_plans",
    ALL_USERS: "/api/v1/admin/list/enterprise/user",
    SMALL_USERS: "/api/v1/admin/list/small/business/user",
    ACTIVE_INACTIVE_SMALL_USER:
      "/api/v1/admin/activate/deactivate/user/account",
    EDIT_SMALL_USER: "/api/v1/admin/update/small/business/user",
    DELETE_USER: "/api/v1/admin/user/delete",
  },
};
export default ApiPath;
