import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    getAllUser: {},
    trafficData: {},
    loading: false
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: ({
        getAllUsers: (state) => {
            state.loading = true
        },
        setGetAllUsers: (state, action) => {
            state.loading = false
            state.getAllUser = action.payload
        },
        trafficData: (state) => {
            state.loading = true
        },
        setTrafficData: (state, action) => {
            state.loading = false
            state.trafficData = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const {
    getAllUsers,
    setGetAllUsers,
    trafficData,
    setTrafficData,
    onErrorStopLoad 
} = dashboardSlice.actions

export default dashboardSlice.reducer