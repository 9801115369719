import React, { useState, useEffect, useRef } from "react";
import * as Images from "../../../utilities/images";
import ReactApexChart from 'react-apexcharts';
import { useDispatch } from "react-redux";
import { trafficData } from "../../../redux/slices/dashboard";
// import { MDBContainer } from "mdbreact";
import { Bar } from 'react-chartjs-2';

const AccountDashboard = () => {

    const dispatch = useDispatch()
    const [monthArray, setMonthArray] = useState()
    const [countArray, setCountArray] = useState()
    const [browserArray, setBrowserArray] = useState("")
    const [browserCountArray, setBrowserCountArray] = useState()
    const [totalUsers, setTotalUsers] = useState("") 

    const getTrafficOverview = () => {
        let params = {
        }
        dispatch(trafficData({
            ...params, cb(res) {
                if (res.data) {
                    const monthArray = [];
                    const countArray = [];
                    res?.data?.payload?.monthsData.forEach(item => {
                        monthArray.push(item.month);
                        countArray.push((item.count));
                    });
                    setTotalUsers(res?.data?.payload?.totalUsers)
                    const browserData = res?.data?.payload?.browserData
                    // Separate browser names and values into different arrays
                    const browserNamesArray = Object?.keys(browserData);
                    const browserValuesArray = Object?.values(browserData);
                    setMonthArray(monthArray)
                    setCountArray(countArray)
                    setBrowserArray(browserNamesArray)
                    setBrowserCountArray(browserValuesArray)

                }
            }
        }))
    }

    const trafficOverviewData = {
        series: [
            {
                data: countArray
            },

        ],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },

            xaxis: {
                type: 'month',
                categories: monthArray,
                labels: {
                    formatter: function (val) {
                        // Convert the month number to a date string for display
                        const date = new Date(2000, val - 1, 1); // Using 2000 as the year (it doesn't matter for just months)
                        return date.toLocaleDateString('en-US', { month: 'short' }); // Display short month name
                    }
                }
            },
            yaxis: {
                min: 0, // Set minimum value to start from 50
                max: 500, // Set maximum value to end at 250
                tickAmount: 5, // Number of intervals (50, 100, 150, 200, 250)
            },
            selection: {
                enabled: false // Disable selection (optional)
            },
            tooltip: {
                enabled: false, // Disable tooltip (hover effect)
            },
            toolbar: {
                show: false, // Hide the entire toolbar
            },
            colors: ["#cadfff", "#a9cbff"]
        },
    };

    const trafficSourceData = {
        series: [{
            data: browserCountArray
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'center', // Display data labels at the center of the bars
                    },
                }
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: ["#c5c5c5"]
                }
            },
            xaxis: {
                type: "category",
                categories: browserArray,
            },
            yaxis: {
                min: 0, // Set minimum value to start from 50
                max: 500, // Set maximum value to end at 250
                tickAmount: 4, // Number of intervals (50, 100, 150, 200, 250)
            },
        },
    }

    useEffect(() => {
        getTrafficOverview()
        document.title = "Dashboard";
    }, []);

    return (
        <div className='containerBox adminDashbord'>
            <h3 className='headTxtBlueSmall mb-3'>Admin Dashboard</h3>
            <div className="row">
                <div className="col-md-8">
                    <div className="mb-5">
                        <h1 className="heading20">Traffic Overview</h1>
                        <p className="headTxtBlueSmall">{totalUsers} <span className="subText13">Users</span></p>
                        <div id="chart">
                            <ReactApexChart options={trafficOverviewData.options} series={trafficOverviewData.series} type="area" height={350} />
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <h1 className="heading20 mb-5">Traffic Details</h1>
                    <div className="trafficDetails mb-4">
                        <span className="timeSpot">New Sessions %</span>
                        <h1 className="heading48 my-2">61.1%</h1>
                        <span className="subText13 userTxtBold">1,245 <span className="fw-normal">Users</span></span>
                    </div>
                    <div className="trafficDetails">
                        <span className="timeSpot">Time on Site</span>
                        <h1 className="heading48 my-2">1m20s</h1>
                        <span className="subText13 userTxtBold">+9.09% <span className="fw-normal">Since Last Month</span></span>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-7">
                    <div className="taffifeSource">
                        <h1 className="heading20 mb-3">Traffic Source</h1>
                        <img src={Images.tarrifSource} alt="tarrife overview" className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-5">
                    <h1 className="heading20 mb-3">Traffic Source</h1>
                    <ReactApexChart options={trafficSourceData.options} series={trafficSourceData.series} type="bar" height={350} />
                </div>
            </div>
        </div>
    )
}

export default AccountDashboard
