import { Outlet} from 'react-router-dom';
import MainFooter from '../components/common/authFooter';
import MainNav from '../components/common/mainNav'


const MainLayout = () => {

  return (
    <>
      <MainNav />
      <Outlet />
      <MainFooter />
    </>
  );
};

export default MainLayout;