import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    getarticleCategory:{},
    createHelpPage:{},
    getAllHelpDeskListing:{},
    deleteDeskItem:{},
    getSingleDeskItem:{},
    updateDeskItem:{},
    articleSearchKey:""
}

export const helpDeskSlice = createSlice({
    name: 'helpDesk',
    initialState,
    reducers: ({
        getArticleCategory: (state) => {
            state.loading = true
        },
        setGetArticleCategory: (state, action) => {
            state.loading = false
            state.getarticleCategory = action.payload
        },
        createHelpPage: (state) => {
            state.loading = true
        },
        setCreateHelpPage: (state, action) => {
            state.loading = false
            state.createHelpPage = action.payload
        },
        getAllHelpDeskListing: (state) => {
            state.loading = true
        },
        setGetAllHelpDeskListing: (state, action) => {
            state.loading = false
            state.getAllHelpDeskListing = action.payload
        },
        deleteDeskItem: (state) => {
            state.loading = true
        },
        setDeleteDeskItem: (state, action) => {
            state.loading = false
            state.deleteDeskItem = action.payload
        },
        getSingleDeskItem: (state) => {
            state.loading = true
        },
        setGetSingleDeskItem: (state, action) => {
            state.loading = false
            state.deleteDeskItem = action.payload
        },
        updateDeskItem: (state) => {
            state.loading = true
        },
        searchKeyArtcile: (state, action) => {
            state.articleSearchKey = action.payload.articleSearchKey;
        },
        setupdateDeskItem: (state, action) => {
            state.loading = false
            state.updateDeskItem = action.payload
        },
    })
})

export const {
    getArticleCategory,
    setGetArticleCategory,
    createHelpPage,
    setCreateHelpPage,
    getAllHelpDeskListing,
    setGetAllHelpDeskListing,
    deleteDeskItem,
    setDeleteDeskItem,
    getSingleDeskItem,
    setGetSingleDeskItem,
    updateDeskItem, 
    setupdateDeskItem,
    searchKeyArtcile
} = helpDeskSlice.actions

export default helpDeskSlice.reducer