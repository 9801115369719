import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { useDispatch } from "react-redux";
import {
  activeInactiveSmallUsers,
  deleteSmallUser,
  getSmallUsers,
  getUsersList,
  searchKey,
} from "../../../redux/slices/users";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { useUserSelector } from "../../../redux/selector/users";
import { toast } from "react-toastify";
import swal from "sweetalert";

const SmallUsers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userSelector = useUserSelector();
  const [search, setSearch] = useState(userSelector?.enterpriseSearchKey);
  const [pageLimit, setPageLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [listing, setListing] = useState([]);
  const [loading, setLoading] = useState(true)

  const getListing = (page, limit) => {
    setLoading(true)
    let params = {
      page: page ? page : currentPage,
      limit: limit ? limit : pageLimit,
      search: search ? search : "",
    };
    dispatch(searchKey({ enterpriseSearchKey: search }));
    dispatch(
      getSmallUsers({
        ...params,
        cb(res) {
          if (res.data) {
            setListing((prev) => {
              setLoading(false)
              return res?.data?.payload?.data
            });
            setTotalPage(res?.data?.payload?.total_pages);
          }
        },
      })
    );
  };

  const activeInactiveUserAction = (value, id, currentState) => {
    if (currentState == value) {
      return
    }
    swal({
      // title: "Are you sure?",
      text: `Are you sure want to ${value === 1 ? "deactivate" : "activate"} user?`,
      // icon: "warning",
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (result) {
        if (!id) {
          toast.error("Please Select Correct values");
          return;
        }
        setLoading(true)
        const params = {
          is_admin_deactivate: value,
          id: id,
        };
        dispatch(
          activeInactiveSmallUsers({
            ...params,
            cb(res) {
              if (res.data) {
                getListing();
              }
            },
          })
        );
      }
    });
  };

  //on change page pagination
  const handlePageClick = (e) => {
    const pageNo = e.selected + 1;
    setLoading(true)
    setCurrentPage(pageNo);
    getListing(pageNo, pageLimit);
  };

  const handelEdit = (id, data) => {
    if (data.step != 6) {
      toast.dismiss();
      toast.error("User Profile is Incomplete!");
      return;
    }

    if (id) {
      navigate(`/edit-small-user/${id}`);
    }
  };


  //reset input
  const onChangeReset = () => {
    setSearch("");
    dispatch(searchKey({ enterpriseSearchKey: "" }));
  };

  useEffect(() => {
    setLoading(true)
    getListing();
  }, [search]);

  useEffect(() => {
    getListing();
  }, []);


  const handleDeleteUser = (userId) => {
    swal({
      text: `Are you sure want to delete this user?`,
      dangerMode: false,
      buttons: true,
    }).then((result) => {
      if (!result) return;
      const params = {
        userId: userId
      }
      dispatch(
        deleteSmallUser({
          ...params,
          cb(res) {
            getListing();
          }
        })
      )
    })
  }
  return (
    <>
      <div className="containerBox adminDashbord">
        <h3 className="headTxtBlueSmall mb-3">Small Business Users</h3>
        <div className="searchFilter mt-5">
          <div className="selectBoxs">
            {/* <select className='supportFilter me-3' value={articleCategoryId} onChange={(e) => setArticleCategoryId(e.target.value)}>
                            <option disabled value="">Search Category</option>
                            <option value="">All</option>
                            {articleCategory && articleCategory?.map((val, index) => {
                                return <option value={val?.id} key={index}>{val?.name}</option>
                            })}
                        </select> */}
            <div className="searchByKeywordOuter">
              <input
                type="text"
                value={search}
                className="supportFilter"
                placeholder="Search By Keyword"
                onChange={(e) => setSearch(e.target.value)}
              />
              {search !== "" ? (
                <button
                  className="inputClose "
                  type="reset"
                  onClick={() => {
                    onChangeReset();
                  }}
                >
                  {" "}
                  <i className="las la-times"></i>{" "}
                </button>
              ) : search === undefined ? (
                ""
              ) : (
                ""
              )}
            </div>
          </div>
          {/* <Link to="/add-user" className='postBtn'>Add New User</Link> */}
        </div>
        <div className="table-responsive">
          <table className="manageUserTable mt-5 userTable">
            <thead>
              <tr>
                <th>Joined On</th>
                <th>Name</th>
                <th>Company Name</th>
                <th>Email</th>
                <th>Plan</th>
                <th style={{ width: "10%", textAlign: "center" }}>Role</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            {!loading && (
              <>
                {listing?.length > 0 ? (
                  <tbody>
                    {listing?.map((data, index) => (
                      <tr key={index}>
                        {/* <td>{data?.title}</td> */}
                        <td>{moment(data?.created_at).format("MMM D, YYYY")}</td>
                        <td>
                          {data?.user_profile?.first_name}{" "}
                          {data?.user_profile?.last_name}
                        </td>
                        <td>
                          {data?.user_company_information?.company_name ?? "NA"}
                        </td>
                        <td>{data?.email}</td>

                        <td>{data?.membership_details?.name ?? "NA"} <br />
                          <span className="manageUserTable">
                            Exp: {data?.expiry_date ? moment(data?.expiry_date).format("MMM D, YYYY") : "NA"}
                          </span></td>
                        <td style={{ textAlign: "center" }}>
                          {data?.user_role?.role_id === 1
                            ? "Buyers"
                            : data?.user_role?.role_id === 2
                              ? "Suppliers"
                              : "NA"}
                        </td>
                        <td>
                        <div
                            className={`dropdown me-2 ${data?.deletedAt ? "arrowRemove" : ""}`}
                            id="statuDropdownOuter"
                          >
                            <button
                              className={`btn dropdown-toggle 
                                                                                ${
                                                                                  !data?.deletedAt &&  data?.is_admin_deactivate ==
                                                                                  0
                                                                                    ? ""
                                                                                    : "statuDropdown"
                                                                                }`}
                              type="button"
                              id="statuDropdown"
                              data-bs-toggle={data?.deletedAt ?"": "dropdown"}
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {data?.deletedAt ? "Deleted" : data?.is_admin_deactivate == 0
                                ? "Active"
                                : "Inactive"}
                            </button>
                            <div
                              className="dropdown-menu customDrop__"
                              aria-labelledby="statuDropdown"
                            >
                              <a
                                className={`dropdown-item 
                                                                    ${
                                                                      !data?.deletedAt && data?.is_admin_deactivate ==
                                                                      0
                                                                        ? "dropdownSelected"
                                                                        : ""
                                                                    }`}
                                onClick={() => {
                                  activeInactiveUserAction(
                                    0,
                                    data?.id,
                                    data?.is_admin_deactivate
                                  );
                                }}
                              >
                                Active
                              </a>
                              <a
                                className={`dropdown-item 
                                                                            ${
                                                                              !data?.deletedAt && data?.is_admin_deactivate ==
                                                                              1
                                                                                ? "dropdownSelected"
                                                                                : ""
                                                                            }`}
                                onClick={() => {
                                  activeInactiveUserAction(
                                    1,
                                    data?.id,
                                    data?.is_admin_deactivate
                                  );
                                }}
                              >
                                {" "}
                                InActive
                              </a>
                              <a
                                className={`dropdown-item ${data?.deletedAt ? "dropdownSelected": ""}`}
                                onClick={() => handleDeleteUser(data?.id, data)}
                              >
                                {" "}
                                Delete
                              </a>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex gap-2 justify-content-center">
                            <img
                              src={Images.editIcon}
                              alt="icon"
                              className="trashIcon me-2"
                              onClick={() => handelEdit(data?.id, data)}
                            />
                           {/* {data?.deletedAt ? "Deleted":  <img
                              src={Images.deletedicon}
                              alt="icon"
                              className="trashIcon me-2"
                              onClick={() => handleDeleteUser(data?.id, data)}
                            />} */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <span className="text-center">No data found</span>
                )}
              </>
            )}
          </table >
        </div>

        {loading && (
          <div className="d-flex justify-content-center py-5 mx-auto">
            <div
              className="spinner-grow text-primary"
              role="status"
            ></div>
          </div>)}
        <div className="col-12 ">
          <div className="customPagination mt-4">
            <div className="pagination">
              {listing?.length > 0 ? (
                <ReactPaginate
                  // nextLabel={'Next ' + <span>{'>>'}</span>}
                  nextLabel={
                    <span>
                      Next <span className="double-arrow">{">>"}</span>
                    </span>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  // pageRangeDisplayed={pageCount}  // Show only the starting 2 and last 2 pages
                  marginPagesDisplayed={2}
                  // pageRangeDisplayed={pageCount}
                  pageCount={totalPage}
                  // previousLabel={'<< Previous'}
                  previousLabel={
                    <span>
                      <span className="double-arrow h5">{"<<"}</span> Previous
                    </span>
                  }
                  renderOnZeroPageCount={null}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* :<tr><td colSpan={5}><p className='text-center'> <b>No Users Found</b></p></td></tr> */}
      </div >
    </>
  );
};

export default SmallUsers;
