import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
// import { CKEditor } from 'ckeditor4-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createHelpPage, getArticleCategory } from '../../../redux/slices/helpDesk';
import { useDispatch } from 'react-redux';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from "react-toastify"

const PostArtical = () => {


    const [title, setTitle] = useState("")
    const [textData, setTextData] = useState("")
    const [articleCategory, setArticleCategory] = useState("")
    const [articleCategoryId, setArticleCategoryId] = useState("")
    const [isSuggested, setIsSuggested] = useState(0)
    const [isRecommended, setIsRecommended] = useState(0)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // new date store todays date
    const [date, setDate] = useState(new Date())

    // remove html tags from description
    const stripHtmlTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };


    // onchange date set date in the state
    const onChangeDateResponse = (date) => {
        setDate(date)
    }

    // checkbox for is recommended and is suggested
    const handleChange = (e, flag) => {
        const val = e.target.checked
        if (flag === "isSuggested") {
            if (val == true) {
                setIsSuggested(1)
            }
            else {
                setIsSuggested(0)
            }
        }
        else if (flag === "isRecommended") {
            if (val == true) {
                setIsRecommended(1)
            }
            else {
                setIsRecommended(0)
            }
        }
    }

    const getArticleCategories = () => {
        let params = {
        }
        dispatch(getArticleCategory({
            ...params, cb(res) {
                if (res.data) {
                    setArticleCategory(res?.data?.payload)
                }
            }
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!title || title.trim() === "") {
            toast.error("Please enter title")
            return;
        }
        else if (title.length > 100) {
            toast.error("Article title can not be more than 50 characters long")
            return;
        }
        else if (textData === "") {
            toast.error("Please enter description")
            return;
        }
        else if (articleCategoryId === "") {
            toast.error("Please choose article catgeory")
            return;
        }
        let params = {
            title: title,
            date: date,
            description: textData,
            help_category_id: Number(articleCategoryId),
            is_recommended: isRecommended,
            is_suggested: isSuggested
        }
        dispatch(createHelpPage({
            ...params, cb(res) {
                if (res.data) {
                    navigate("/help-desk")
                }
            }
        }))
    }

    const handleEditorData = (event, editor) => {
        const data = editor.getData();
        setTextData(data)
    };


    useEffect(() => {
        getArticleCategories()
    }, [])


    return (
        <> 
            <div className='containerBox adminDashbord'>

                <div className='searchFilter'>
                    <h3 className='headTxtBlueSmall mb-3'>Post Article</h3>
                    <Link to="/help-desk" className='postBtn'>Go Back</Link>
                </div>
                <form className='form mt-5' onSubmit={(e) => handleSubmit(e)}>
                    <div className='row'>
                        <div className='col-md-8 mb-4'>
                            <div className='form-group'>
                                <label className='customLabel mb-2'>Article Title <span className='mendatoryFields'>*</span></label>
                                <input name="email" type='text' className='customFormControl' placeholder='' value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <label className='customLabel mb-2'>Date <span className='mendatoryFields'>*</span></label>
                            <div className='form-group'>
                                <DatePicker
                                    className='customFormControl'
                                    selected={date}
                                    onChange={onChangeDateResponse}
                                    // maxDate={moment().toDate()}
                                    dateFormat="MMMM d, yyyy" // Format as "Month Day, Year"
                                    placeholderText="Month Day, Year" // Example placeholder text
                                    maxDate={new Date()}
                                />
                            </div> 
                        </div>
                        <div className='col-md-12 mb-4'>
                            <label className='customLabel mb-2'>Article Answer <span className='mendatoryFields'>*</span></label>
                            <div className='form-group'>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={textData}
                                    onChange={handleEditorData}
                                    // config={{
                                    //     removePlugins: ['ImageUpload', 'ImageUploadUI'] // Remove image upload plugins
                                    // }}
                                    config={{
                                        removePlugins: [
                                            "EasyImage",
                                            "ImageUpload",
                                            "MediaEmbed",
                                            "Table",
                                            "table",
                                            "TableToolbar",
                                            "image",
                                        ],

                                        toolbarLocation: ["bottom"],
                                        // removeButtons:
                                        //     "Table,TableToolbar,Anchor,HorizontalRule,Blockquote",
                                    }}
                                    name="editor"
                                    readOnly={false}
                                />
                                {/* <textarea className='customFormControl' rows={7}></textarea> */}
                            </div>
                        </div>
                        <div className='col-md-4 mb-4'>
                            <div className='form-group'>
                                <label className='customLabel mb-2'>Article Category <span className='mendatoryFields'>*</span></label>
                                <select className='customSelect w-100' value={articleCategoryId} onChange={(e) => setArticleCategoryId(e.target.value)}>
                                    <option disabled value="">Search By Category</option>
                                    {articleCategory && articleCategory?.map((val, index) => {
                                        return <option value={val?.id} key={index}>{val?.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="form-group col-md-12 mb-3">
                            <div className='d-flex'>
                                <input type="checkbox" id="isRecommended" className='checkBox me-2' value={isRecommended} onChange={(e) => {
                                    handleChange(e, "isRecommended")
                                }} />
                                <label htmlFor="isRecommended" className='checkBoxTxt align-items-start align-items-md-center'>
                                    <span>
                                        Is recommended
                                    </span>
                                </label>


                            </div>
                        </div>
                        <div className="form-group col-md-12 mb-3">
                            <div className='d-flex'>
                                <input type="checkbox" id="isSuggested" className='checkBox me-2' value={isSuggested} onChange={(e) => {
                                    handleChange(e, "isSuggested")
                                }} />
                                <label htmlFor="isSuggested" className='checkBoxTxt align-items-start align-items-md-center'>
                                    <span>
                                        Is suggested
                                    </span>
                                </label>


                            </div>
                        </div>

                        <div className='col-12 text-end mt-5'>
                            <button className='submitBtn  mt-2 d-inline mt-5' type="submit" >
                                <span> Submit Connection </span>
                            </button>
                        </div>



                    </div>
                </form>
            </div>
        </>
    )
}

export default PostArtical