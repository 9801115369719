import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  supplierDescription: {},
  businessOwnershipType: {},
  industryGroups: {},
  industrySubGroups: {},
  userAdd: {},
  getPlans: {},
  getUsersList: {},
  search: "",
  loading: false,
  enterpriseSearchKey: "",
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    diverseSupplierDescription: (state) => {
      state.loading = true;
    },
    setDiverseSupplierDescription: (state, action) => {
      state.loading = false;
      state.supplierDescription = action.payload;
    },
    businessOwnershipType: (state) => {
      state.loading = true;
    },
    setBusinessOwnershipType: (state, action) => {
      state.loading = false;
      state.businessOwnershipType = action.payload;
    },
    companyLogoUpload: (state) => {
      state.loading = true;
    },
    setCompanyLogoUpload: (state, action) => {
      state.loading = false;
      state.editProfile = action.payload;
    },
    getAllIndustryGroups: (state) => {
      state.loading = true;
    },
    setGetAllIndustryGroups: (state, action) => {
      state.loading = false;
      state.industryGroups = action.payload;
    },
    getAllIndustrySubGroups: (state) => {
      state.loading = true;
    },
    setGetAllIndustrySubGroups: (state, action) => {
      state.loading = false;
      state.industrySubGroups = action.payload;
    },
    existingCertification: (state) => {
      state.loading = true;
    },
    setExistingCertification: (state) => {
      state.loading = false;
    },
    userAdd: (state) => {
      state.loading = true;
    },
    setUserAdd: (state, action) => {
      state.loading = false;
      state.userAdd = action.payload;
    },
    userView: (state) => {
      state.loading = true;
    },
    setUserView: (state, action) => {
      state.loading = false;
    },
    updateEnterprise: (state) => {
      state.loading = true;
    },
    UpdateSmallUsers: (state) => {
      state.loading = true;
    },
    setUpdateEnterprise: (state, action) => {
      state.loading = false;
    },
    getPlans: (state) => {
      state.loading = true;
    },
    setGetPlans: (state, action) => {
      state.loading = false;
      state.getPlans = action.payload;
    },
    getUsersList: (state) => {
      state.loading = true;
    },
    getSmallUsers: (state) => {
      state.loading = true;
    },
    activeInactiveSmallUsers: (state) => {
      state.loading = true;
    },
    setGetUsersList: (state, action) => {
      state.loading = false;
      state.getUsersList = action.payload;
    },
    searchKey: (state, action) => {
      state.enterpriseSearchKey = action.payload.enterpriseSearchKey;
    },
    deleteSmallUser: (state, action) => {

    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  diverseSupplierDescription,
  setDiverseSupplierDescription,
  businessOwnershipType,
  setBusinessOwnershipType,
  companyLogoUpload,
  setCompanyLogoUpload,
  getAllIndustryGroups,
  setGetAllIndustryGroups,
  getAllIndustrySubGroups,
  setGetAllIndustrySubGroups,
  existingCertification,
  setExistingCertification,
  userAdd,
  setUserAdd,
  userView,
  setUserView,
  updateEnterprise,
  setUpdateEnterprise,
  getPlans,
  setGetPlans,
  getUsersList,
  setGetUsersList,
  searchKey,
  getSmallUsers,
  activeInactiveSmallUsers,
  UpdateSmallUsers,
  deleteSmallUser,
} = userSlice.actions;

export default userSlice.reducer;
