import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setGetAllUsers, setTrafficData} from '../../slices/dashboard';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { setCreateHelpPage, setDeleteDeskItem, setGetAllHelpDeskListing, setGetArticleCategory, setGetSingleDeskItem, setupdateDeskItem } from '../../slices/helpDesk';


function* getArticleCategory(action) {
  try {
    const resp = yield call(ApiClient.get, action.url= ApiPath.HelpDeskApiPath.ARTICLE_CATEGORY ,action.payload = action.payload);
    if (resp.status) {
        yield put(setGetArticleCategory(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetArticleCategory({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getAllHelpDeskListing(action) {
    try {
        const resp = yield call(ApiClient.get, action.url = `${ApiPath.HelpDeskApiPath.HELP_DESK_LISTING}?page=${action.payload.page}&limit=${action.payload.limit}&search=${action.payload.search}&help_category_id=${action.payload.help_category_id}`,action.payload = action.payload);
      if (resp.status) {
          yield put(setGetAllHelpDeskListing(resp.data.payload));
          yield call(action.payload.cb, action.res = resp)
      }
      else {
        throw resp
      }
    } catch (e) {
      yield put(setGetAllHelpDeskListing({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.msg);
    }
  }

  function* getSingleDeskItem(action) {
    try {
        const resp = yield call(ApiClient.get, action.url =`${ApiPath.HelpDeskApiPath.GET_SINGLE_DESK_ITEM}/${action.payload.id}`,action.payload = action.payload);
      if (resp.status) {
          yield put(setGetSingleDeskItem(resp.data.payload));
          yield call(action.payload.cb, action.res = resp)
      }
      else {
        throw resp
      }
    } catch (e) {
      yield put(setGetSingleDeskItem({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.msg);
    }
  }

function* createHelpPage(action) {
    try {
      const resp = yield call(ApiClient.post, action.url = ApiPath.HelpDeskApiPath.CREATE_HELP_PAGE, action.payload = action.payload);
      if (resp.status) {
           yield put(setCreateHelpPage(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
        toast.success(action?.res?.data?.msg);
      }
      else { 
        throw resp
      }
    } catch (e) {
      yield put(setCreateHelpPage({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.msg);
    }
  }

  function* updateDeskItem(action) {
    try {
      const resp = yield call(ApiClient.patch, action.url = `${ApiPath.HelpDeskApiPath.UPDATE_DESK_ITEM}/${action.payload.id}`, action.payload = action.payload);
      if (resp.status) {
           yield put(setupdateDeskItem(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
        toast.success(action?.res?.data?.msg);
      }
      else { 
        throw resp
      }
    } catch (e) {
      yield put(setupdateDeskItem({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.msg);
    }
  }

  function* deleteDeskItem(action) {
    try {
      const resp = yield call(ApiClient.delete, action.url = `${ApiPath.HelpDeskApiPath.DELETE_DESK_ITEM}/${action.payload.id}`, action.payload = action.payload);
      if (resp.status) {
           yield put(setDeleteDeskItem(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
        toast.success(action?.res?.data?.msg);
      }
      else { 
        throw resp
      }
    } catch (e) {
      yield put(setDeleteDeskItem({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.msg);
    }
  }

function* helpDeskSaga() {
  yield all([
    takeLatest('helpDesk/getArticleCategory', getArticleCategory),
    takeLatest('helpDesk/createHelpPage', createHelpPage),
    takeLatest('helpDesk/getAllHelpDeskListing', getAllHelpDeskListing),
    takeLatest('helpDesk/deleteDeskItem', deleteDeskItem),
    takeLatest('helpDesk/getSingleDeskItem', getSingleDeskItem),
    takeLatest('helpDesk/updateDeskItem', updateDeskItem),
  ])
}

export default helpDeskSaga;