import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UpdateSmallUsers, businessOwnershipType, companyLogoUpload, diverseSupplierDescription, existingCertification, getAllIndustryGroups, getAllIndustrySubGroups, getPlans, getUsersList, updateEnterprise, userAdd, userView } from '../../../redux/slices/users';
import Multiselect from "multiselect-react-dropdown";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify"
import { useUserSelector } from '../../../redux/selector/users';

const EditSmallUser = () => {

  const location = useLocation()
  const userSelector = useUserSelector()
  const navigate = useNavigate()
  const parts = location.pathname.split('/');
  const userId = parseFloat(parts[parts.length - 1]);
  const dispatch = useDispatch()
  const [paymentCheck, setPaymentCheck] = useState()
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    roleId: "",
    plan_id: "",
    paymentstatus: ""
  });

  const [planList, setPlanList] = useState([])

  const handleChange = (e, fieldname) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  }


  // onchange input
  const handleChangeIndustry = (id, flag) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      plan_id: id
    }));
  };

  const plans = (e) => {
    dispatch(
      getPlans({
        cb(res) {
          if (res.status) {
            setPlanList(res?.data?.payload)
          } else {
          }
        },
      })
    );
  };

  const getUserInfo = (id) => {
    let params = {
      id: id
    }
    dispatch(userView({
      ...params, cb(res) {
        if (res.data) {
          setFormData((prevData) => ({
            ...prevData,
            firstName: res?.data?.payload?.user_profile?.first_name,
            lastName: res?.data?.payload?.user_profile?.last_name,
            email: res?.data?.payload?.email,
            companyName: res?.data?.payload?.user_company_information?.company_name,
            roleId: res?.data?.payload?.user_role?.role_id,
            plan_id: res.data.payload?.user_membership_data[0]?.membership_id ? res.data.payload?.user_membership_data[0]?.membership_id : "1",
            paymentstatus: res?.data?.payload?.is_enterprise_payment_done
          }))
          setPaymentCheck(res?.data?.payload?.is_enterprise_payment_done)
        }
      }
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let letterVal = /^[a-zA-Z\s]*$/;
    if (!formData.plan_id) {
      toast.error("Please select plan");
      return
    }
    else if (!formData.companyName || formData.companyName.trim() === "") {
      toast.error("Please enter Company name");
      return;
    } else if (formData.companyName.length <= 2) {
      toast.error("Company name should be minimum 3 character");
      return;
    }
    else if (!formData.roleId) {
      toast.error("Please Select Role");
      return
    }
    else if (!formData.firstName || formData.firstName.trim() === "" || formData.firstName.length < 2) {
      toast.error("Please enter first name");
      return;
    }
    else if (!formData.firstName.match(letterVal)) {
      toast.error('Please enter in first name alphabet characters only')
      return;
    }
    else if (!formData.lastName || formData.lastName.trim() === "" || formData.lastName.length < 2) {
      toast.error("Please enter last name");
      return;
    }
    else if (!formData.lastName.match(letterVal)) {
      toast.error('Please enter in last name alphabet characters only')
      return;
    }
    let params = {
      id: userId,
      first_name: formData.firstName,
      last_name: formData.lastName,
      company_name: formData.companyName,
      role_id: formData.roleId,
    };
    dispatch(
      UpdateSmallUsers({
        ...params,
        cb(res) {
          toast.success(res?.data?.msg)
          navigate("/small-users")
        },
      })
    );
  }

  useEffect(() => {
    plans()
  }, [])  
 
  useEffect(() => {
    if (userId) {
      getUserInfo(userId)
    }
  }, [userId])

  return (
    <>
      <div className='containerBox adminDashbord'>

        <div className='searchFilter'>
          <h3 className='headTxtBlueSmall mb-3'>Edit Small Business User</h3>
          <Link to="/small-users" className='postBtn'>Go Back</Link>
        </div>
        <form className='form mt-5' onSubmit={(e) => handleSubmit(e)}>
          <div className='row'>
          <div className='form-group col-md-6 mb-3'>
              <label for="" className='customLabel'>First Name <span className='fieldMandatory'>*</span></label>
              <input onChange={(e) => handleChange(e)} value={formData.firstName} name="firstName" type='text' className='customFormControl' placeholder='First Name ' />
            </div>
            <div className='form-group col-md-6 mb-3'>
              <label for="" className='customLabel'>Last Name <span className='fieldMandatory'>*</span></label>
              <input onChange={(e) => handleChange(e)} maxLength={25} value={formData.lastName} name="lastName" type='text' className='customFormControl' placeholder='Last Name ' />
            </div>
            <div className='form-group col-md-6 mb-3'>
              <label for="" className='customLabel'>Company Name <span className='fieldMandatory'>*</span></label>
              <input onChange={(e) => handleChange(e)} maxLength={25} value={formData.companyName} name="companyName" type='text' className='customFormControl' placeholder='Company Name ' />
            </div>
            <div className="form-group col-md-6 mb-3">
              <label for="" className='customLabel'>Role <span className='fieldMandatory'>*</span></label>
              <select
                className="cateSelectbox"
                name='roleId'
                // required=""
                onChange={(e) => handleChange(e)}
                value={formData?.roleId}
                // onChange={(e) => {
                //   handleChangeIndustry(e.target.value, "");
                // }}
              >
                <option value="" disabled>
                  Select Role
                </option>
                <option value="1">
                  Buyers
                </option>
                <option value="2">
                  Suppliers
                </option>
              </select>
            </div>
           
          </div>
          <div className='col-12 text-end mt-5'>
            <button className='submitBtn  mt-2 d-inline' type="submit" onClick={(e) => handleSubmit(e)}>
              {userSelector?.loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default EditSmallUser