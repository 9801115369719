import { Navigate, Outlet } from "react-router-dom";

const PrivateCheck = ({ auth }) => {
    const authToken = localStorage.getItem("adminAuthToken") ? true : false;

    return (
        <>
            {
                (authToken && auth) ? <Outlet /> :
                    (authToken && !auth) ? <Navigate to="/dashboard" /> :
                        (!authToken && !auth) ? <Outlet /> : <Navigate to="/" />
            }
        </>
    )
}

export default PrivateCheck;
