import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "./auth";
import { dashboardSlice } from "./dashboard";
import { helpDeskSlice } from "./helpDesk";
import { userSlice } from "./users";

export const mainReducer = combineReducers({
    auth: authSlice.reducer,
    dashboard: dashboardSlice.reducer,
    helpDesk: helpDeskSlice.reducer,
    users: userSlice.reducer
})