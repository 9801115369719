import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthNav from "../../components/common/authNav";
import * as Images from "../../../utilities/images";
import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';
// import OwlCarousel from 'react-owl-carousel';
const Home = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("");
    const plusStyle = {
        color: "#388E1E",
        fontSize: "14px"
    }
    //create Account by select role
    const handleSubmit = (flag) => {
        if (flag === "Buyer") {
            navigate('/signup', { state: { id: 1 } });
        }
        else {
            navigate('/signup', { state: { id: 2 } });
        }
    }
    useEffect(() => {
        if(location.hash){
            let element = document.getElementById(location.hash.replace("#", ""));
            element.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
        }
        document.title = "Home-page"
    }, []);

    return (
        <div className="homePage">
            {/* Start HomePage Section */}
            <section className="homeBanner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="leftSec">
                                <h3 className="bannerHead">
                                    Connecting <b>Small, Large and Diverse Buyers</b> and <b>Suppliers</b>.
                                </h3>
                                <p className="bannerTxt">
                                    GetOPP$ is a bridge for large, medium, small and diverse businesses looking to source and supply their goods and services from and to each other. A safe space where Buyers and Suppliers create trusted relationships.
                                </p>
                                <p className="bannerTxt">
                                    We are the first platform to fuse procurement needs and business networking activities with a business social networking platform.
                                </p>
                                <div className="btn-group mt-4 d-block d-sm-flex">
                                    <button onClick={(e) => handleSubmit("Buyer")} className="primaryBtnOther mb-2 me-3 d-flex justify-content-between align-items-center">
                                        <span className="">
                                            Buyer
                                        </span>
                                        <span><i className="las la-angle-right"></i></span>
                                    </button>
                                    <button onClick={(e) => handleSubmit("Suppliers")} className="secondaryBtn mb-2 d-flex justify-content-between align-items-center">
                                        <span className="me-5">Suppliers</span>
                                        <i className="las la-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-6  d-none d-lg-block">
                            <img src={Images.bannerImg} className='rightImg' alt="RightImg" />
                        </div> */}
                    </div>
                </div>
            </section>
            {/* End HomePage Section */}
            {/* Start BuyerService Section */}
            <section className="buyerService" id="buyers">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-3 mb-md-0">
                            <p className="outerBtn mb-1">BUYER SERVICES</p>
                            <h4 className="heading_Blue">
                                A revolutionary tool to <b>Identify Suppliers</b> for all of your <b>Contracting Needs.</b>
                            </h4>
                            <p className="innerSubtext mt-2 mb-2">
                                GetOpp$ is a professional network and social communication platform where Buyers can search for Suppliers based on a diverse range of filters with direct access for communication and due diligence research. GetOPP$ creates a safe space for Buyers and Suppliers to contract.
                            </p>
                            <button  onClick={(e) => handleSubmit("Buyer")} className="primaryBtnOther mt-4 d-flex justify-content-between align-items-center">
                                <span>Get Started</span>
                                <i className="las la-angle-right"></i>
                            </button>
                        </div>
                        <div className="col-md-6">
                            {/* <div className="imgContainer">
                                <img src={Images.buyerService} className='secImg' alt='BuyerService' />
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* End BuyerService Section */}
            {/* Start SupplierService Section */}
            <section className="supplierService" id="suppliers">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-3 mb-md-0">
                            {/* <div className="imgContainer">
                                <img src={Images.supplierService} className='secImg' alt='BuyerService' />
                            </div> */}
                        </div>
                        <div className="col-md-6">
                            <p className="outerBtn mb-1">SUPPLIER SERVICES</p>
                            <h4 className="heading_Blue">
                                GetOpp$ is the revolutionary way to be informed of and <b>seek new contracting opportunities.</b>
                            </h4>
                            <p className="innerSubtext mt-2 mb-2">
                                Suppliers receive direct access to large, medium, small and diverse Buyers. The platform tools provide a guide to assist in the quest to achieve prospective long term success, sustainable business models and revenue generation.
                            </p>
                            <button onClick={(e) => handleSubmit("Suppliers")} className="primaryBtnOther mt-4 d-flex justify-content-between align-items-center">
                                <span>Get Started</span>
                                <i className="las la-angle-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            {/* End SupplierService Section */}
            {/* Start Opportunites Section */}
            <section className="opportunites">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <p className="otherLink">Opportunities</p>
                            <h4 className="heading_Blue">
                                <b>GetOpp$</b> aims to eliminate the void of no response and lack of communication between <b>Buyers</b> and <b>Suppliers.</b>
                            </h4>
                            <p className="innerSubtext mt-2 mb-2">
                                GetOpp$ provides an inclusive and trusted way to gain opportunities for long term relationships between large, medium, [KK1] small and diverse Buyers and Suppliers. Buyers set specific opportunity requirements and parameters while Suppliers search for opportunities to meet their specific skill, certification and service specialties.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <ul className="opportunitesDetails">
                                <li className={`opptItems ${activeTab == 'Accounting' ? 'active' : ''}`} onClick={() => setActiveTab("Accounting")}>
                                    <span className="innerSubtext"><b>Accounting</b></span>
                                    <span className="innerSubtext"><b className="me-2">45</b> Companies</span>
                                </li>
                                <li className={`opptItems ${activeTab == 'Automotive' ? 'active' : ''}`} onClick={() => setActiveTab("Automotive")}>
                                    <span className="innerSubtext"><b>Automotive</b></span>
                                    <span className="innerSubtext"><b className="me-2">21</b>Companies</span>
                                </li>
                                <li className={`opptItems ${activeTab == 'Insurance' ? 'active' : ''}`} onClick={() => setActiveTab("Insurance")}>
                                    <span className="innerSubtext"><b>Insurance</b></span>
                                    <span className="innerSubtext"><b className="me-2">6</b>Companies</span>
                                </li>
                                <li className={`opptItems ${activeTab == 'Marketing & Ads' ? 'active' : ''}`} onClick={() => setActiveTab("Marketing & Ads")}>
                                    <span className="innerSubtext"><b>Marketing & Ads</b></span>
                                    <span className="innerSubtext"><b className="me-2">13</b>Companies</span>
                                </li>
                                <li className={`opptItems ${activeTab == 'PublicRelations' ? 'active' : ''}`} onClick={() => setActiveTab("PublicRelations")}>
                                    <span className="innerSubtext"><b>Public Relations</b></span>
                                    <span className="innerSubtext"><b className="me-2">9</b>Companies</span>
                                </li>
                                <li className={`opptItems ${activeTab == 'Software' ? 'active' : ''}`} onClick={() => setActiveTab("Software")}>
                                    <span className="innerSubtext"><b>Software</b></span>
                                    <span className="innerSubtext"><b className="me-2">6</b>Companies</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Opportunites Section */}
            {/* Start BussinessRelation Css */}
            <section className="bussinessRelation">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-md-6">
                            <div className="row pt-4 pb-4 pe-0 pe-md-4 ps-0">
                                <div className="col-md-6">
                                    <div className="d-flex justify-content-between d-md-block">
                                        <div className="img-container mb-4 me-1 me-md-0">
                                            <img src={Images.people} className='peopleImg' alt="People" />
                                            <img src={Images.messageFill} className='outerIcon outerIcon1' alt='Message' />
                                        </div>
                                        <div className="img-container mb-4">
                                            <img src={Images.people1} className='peopleImg' alt="People" />
                                            <img src={Images.checkedThumb} className='outerIcon outerIcon2' alt="CheckedThumb" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="img-container lastImg">
                                        <img src={Images.people2} className='peopleImg' alt="People" />
                                        <img src={Images.linkIcon} className='outerIcon outerIcon3' alt='LinkIcon' />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-md-6">
                            <p className="otherLink"><b>Community</b></p>
                            <h4 className="heading_Blue">
                                Value and importance of <b>connection</b> and <b>community</b> in business relationships.
                            </h4>
                            <p className="innerSubtext mt-2 mb-2">
                                The GetOpp$ platform provides community, professional sharing, education and opportunities to bridge the missing link in contract relationships. All users are encouraged to post relevant company information to raise their companies’ visibility with other users on the platform.
                            </p>
                            <p className="innerSubtext">
                                GetOpp$ is bringing back meaningful professional relationships and connectivity between Buyer and Supplier Communities.
                            </p>
                            <button className="primaryBtnOther mt-4 d-flex justify-content-between align-items-center">
                                <span>Get Started</span>
                                <i className="las la-angle-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
          
        </div>
    )

}

export default Home;