import React from "react"
import { Link } from "react-router-dom";
import * as images from "../../../utilities/images";
import { Nav } from "react-bootstrap";

const AuthFooter = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-sm-4 col-md-3">
                            <h4 className="heading_">Company</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    {/* <Nav.Link className="footerLinks" href="/aboutUs"
                                    >Press & News</Nav.Link> */}
                                      <Nav.Link className="footerLinks"
                                    >Press & News</Nav.Link>
                                </li>
                                <li className="footerItems">
                                    {/* <Link to='/privacyPolicy' className="footerLinks">Privacy Policy</Link> */}
                                    <Link className="footerLinks">Privacy Policy</Link>
                                </li>
                                <li className="footerItems">
                                    {/* <Link to='/termsCondition' className="footerLinks">Terms of Service</Link> */}
                                    <Link className="footerLinks">Terms of Service</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <h4 className="heading_">Community</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    <Link to='#' className="footerLinks">Get Started</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='#' className="footerLinks">Membership</Link>
                                </li>
                                <li className="footerItems">
                                    <Link to='#' className="footerLinks">Resources</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <h4 className="heading_">Support</h4>
                            <ul className="footerMenus">
                                <li className="footerItems">
                                    {/* <Link to='#' className="footerLinks">FAQ's</Link> */}
                                    <Link className="footerLinks">FAQ's</Link>
                                </li>
                                <li className="footerItems">
                                    {/* <Link to='/aboutUs' className="footerLinks">About Us</Link> */}
                                    <Link className="footerLinks">About Us</Link>
                                </li>
                                <li className="footerItems" >
                                    {/* <Link to='/contactUs' className="footerLinks">Contact Us</Link> */}
                                    <Link className="footerLinks">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <section className="footerBottom">
                        <div className="row align-items-center">
                            <div className="col-6 col-md-4">
                                <ul className="socialMenus">
                                    <li className="socialItems">
                                        {/* <Link to='' className="socialLinks"> */}
                                            <img src={images.twitterIcon} alt="Twitter" />
                                        {/* </Link> */}
                                    </li>
                                    <li className="socialItems">
                                        {/* <Link to='' className="socialLinks"> */}
                                            <img src={images.facebookIcon} alt="Facebook" />
                                        {/* </Link> */}
                                    </li>
                                    <li className="socialItems">
                                        {/* <Link to='' className="socialLinks"> */}
                                            <img src={images.instaIcon} alt="Insta" />
                                        {/* </Link> */}
                                    </li>
                                </ul>
                            </div>
                            <div className="col-6 col-md-8 text-end">
                                <p className="subtextInner">
                                    ©{currentYear} OPPSWell
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )

}

export default AuthFooter;