import { all, fork, spawn } from "redux-saga/effects";
import authSaga from "./auth";
import dashboardSaga from "./dashboard";
import helpDeskSaga from "./helpDesk";
import userSaga from "./users";


export default function* rootSaga() {
    yield all([
        spawn(authSaga),
        spawn(dashboardSaga),
        spawn(helpDeskSaga),
        spawn(userSaga)
        // saga1 can also yield [ fork(actionOne), fork(actionTwo) ]
        // fork(saga2),
    ]);
}
