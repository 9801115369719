import React, { useEffect, useState } from 'react'
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Images from "../../../utilities/images";
import { deleteDeskItem, getAllHelpDeskListing, getArticleCategory, searchKeyArtcile } from '../../../redux/slices/helpDesk';
import { useDispatch } from 'react-redux';
import moment from "moment";
import swal from 'sweetalert';
import { useHelpDeskSelector } from '../../../redux/selector/helpDesk';

const HelpDesk = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const helpDeskSelector = useHelpDeskSelector()
    const [articleCategory, setArticleCategory] = useState("")
    const [articleCategoryId, setArticleCategoryId] = useState("")
    const [search, setSearch] = useState(helpDeskSelector?.articleSearchKey)
    const [pageLimit, setPageLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [listing, setListing] = useState([])

    const getListing = (page, limit) => {
        let params = {
            page: page ? page : currentPage,
            limit: limit ? limit : pageLimit,
            search: search ? search : "",
            help_category_id: articleCategoryId,
        }
        dispatch(searchKeyArtcile({articleSearchKey: search}))
        dispatch(getAllHelpDeskListing({
            ...params, cb(res) {
                if (res.data) {
                    setListing(res?.data?.payload?.data)
                    setTotalPage(res.data?.payload?.total_pages)
                }
            }
        }))
    }

    const handelEdit = (id) => {
        if (id) {
            navigate(`/edit-article/${id}`)
        }
    }

    //reset input
    const onChangeReset = () => {
        setSearch("")
        dispatch(searchKeyArtcile({articleSearchKey: ""}))
    }

    const handleDelete = (id) => {
        swal({
            // title: "Are you sure?",
            text: `Are you sure want to remove the selected article?`,
            // icon: "warning",
            dangerMode: false,
            buttons: true,
        })
            .then(result => {
                if (result) {
                    let params = {
                        id: id,
                    }
                    dispatch(deleteDeskItem({
                        ...params, cb(res) {
                            if (res.status) {
                                getListing()
                            }
                        }
                    }))
                }
            });
    }

    const getArticleCategories = () => {
        let params = {
        }
        dispatch(getArticleCategory({
            ...params, cb(res) {
                if (res.data) {
                    setArticleCategory(res?.data?.payload)
                }
            }
        }))
    }

    //on change page pagination
    const handlePageClick = (e) => {
        const pageNo = e.selected + 1;
        setCurrentPage(pageNo);
        getListing(pageNo, pageLimit)
    };


    useEffect(() => {
        getListing()
        getArticleCategories()
    }, [])

    useEffect(() => {
        getListing()
    }, [search])

    useEffect(() => {
        getListing()
    }, [articleCategoryId])


    return (
        <>
            <div className='containerBox adminDashbord'>
                <h3 className='headTxtBlueSmall mb-3'>Help Desk</h3>
                <div className='searchFilter mt-5'>
                    <div className='selectBoxs'>
                        <select className='supportFilter me-3' value={articleCategoryId} onChange={(e) => setArticleCategoryId(e.target.value)}>
                            <option disabled value="">Search Category</option>
                            <option value="">All</option>
                            {articleCategory && articleCategory?.map((val, index) => {
                                return <option value={val?.id} key={index}>{val?.name}</option>
                            })}
                        </select>
                        <div className='searchByKeywordOuter'>
                            <input type='text' value={search} className='supportFilter' placeholder='Search By Keyword' onChange={(e) => setSearch(e.target.value)} />
                            {
                                search !== "" ?
                                    <button class="inputClose " type="reset" onClick={() => {
                                        onChangeReset()
                                    }}> <i class="las la-times"></i> </button>
                                    :  search === undefined ? "" : ""
                            }
                        </div>
                    </div>
                    <Link to="/post-artical" className='postBtn'>Post New Article</Link>
                </div>
                <table className='manageUserTable mt-5'>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Date</th>
                            <th>Category</th>
                            <th>Tools</th>
                        </tr>
                    </thead>
                    {
                        helpDeskSelector?.loading ? <div className="row">
                            <div className="col-sm-12 text-center pt-5 pb-5">
                                <div className="spinner-grow text-success" role="status"></div>
                            </div>
                        </div>
                            :
                            <>
                                {
                                    listing?.length > 0 ?
                                        <tbody>
                                            {listing?.map((data, index) => (
                                                <tr key={index}>
                                                    <td>{data?.title}</td>
                                                    <td>{moment(data?.date).format('MMM D, YYYY')}</td>
                                                    <td>{data?.help_category_details?.name}</td>
                                                    <td>
                                                        <img src={Images.editIcon} alt="icon" className='trashIcon me-2' onClick={() => handelEdit(data?.id)} />
                                                        <img src={Images.trashIcon} alt="icon" className='trashIcon' onClick={() => handleDelete(data?.id)} />
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody> :
                                        <span className='text-center'>No data found</span>
                                }
                            </>
                    }
                   </table>
                    <div className="col-12 ">
                        <div className="customPagination mt-4">
                            <div className="pagination">
                                {listing?.length > 0 ? (
                                    <ReactPaginate
                                        nextLabel={<span>
                                            Next <span className="double-arrow">{">>"}</span>
                                        </span>}
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={5}
                                        marginPagesDisplayed={2}
                                        pageCount={totalPage}
                                        previousLabel={<span>
                                            <span className="double-arrow h5">{"<<"}</span> Previous
                                        </span>}
                                        renderOnZeroPageCount={null}
                                    />
                                ) : ""}
                            </div>
                        </div>
                    </div>
                  
                    {/* :<tr><td colSpan={5}><p className='text-center'> <b>No Users Found</b></p></td></tr> */}
                
            </div>
        </>
    )
}

export default HelpDesk