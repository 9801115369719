import React, { useEffect } from 'react';
import { Outlet, useLocation} from 'react-router-dom';
import AuthFooter from '../components/common/authFooter';
import AuthNav from '../components/common/authNav';
import SideNav from '../components/common/sideNav';

const AuthLayout = () => {
  const location = useLocation();
  const path = location.pathname;
  const loginPaths = [
    '/',
    '/forgetPassword',
    '/forgetPasswordVerify',
    '/resetPassword'
  ]
  return (
    <>
      <AuthNav />
      {loginPaths.includes(path) ? <Outlet /> :
        <div className='container'>
          <main className='main'>
            <div className="vertical-tab">
              <SideNav />
            </div>
            <div className="content_areas">
              <Outlet />
            </div>
          </main>
        </div>
      }
      <AuthFooter />
    </>
  );
};

export default AuthLayout;