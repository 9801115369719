import React, { useState } from "react"
import * as Images from "../../../utilities/images";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavItem } from "react-bootstrap";

const MainNav = () => {
    const authToken = localStorage.getItem("adminAuthToken") ? true : false;

    return (
        <>
            <div className="authNavBar navbarContain mainNavBar"  >
                <Navbar expand="lg">
                    <Container>
                        <Navbar.Brand href="/">
                            <img src={Images.logo} alt="logo" className="img-fluid" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" className="border-0">
                            <i className="las la-bars barIcon"></i>
                        </Navbar.Toggle>
                        {authToken ?
                            <NavItem>
                                <Nav.Link href="/dashboard" className="primaryBtn">Dashboard</Nav.Link>
                            </NavItem>
                            :
                            ""
                        }
                    </Container>
                </Navbar>
            </div>
        </>
    )

}

export default MainNav;