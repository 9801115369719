import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setGetAllUsers, setTrafficData } from '../../slices/dashboard';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { setBusinessOwnershipType, setCompanyLogoUplaod, setCompanyLogoUpload, setDiverseSupplierDescription, setExistingCertification, setGetAllIndustryGroups, setGetAllIndustrySubGroups, setGetPlans, setGetUsersList, setUpdateEnterprise, setUserAdd, setUserView } from '../../slices/users';



function* DiverseSupplierDescription(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.UserApiPath.DRIVERS_SUPPLIER_DESCRIPTION, action.payload = action.payload);
    if (resp.status) {
      yield put(setDiverseSupplierDescription(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setDiverseSupplierDescription({}));
    yield put(onErrorStopLoad())
    // toast.error(e.response.data.msg);
  }
}

function* ownershipType(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.UserApiPath.BUSINESS_OWNERSHIP_TYPE, action.payload = action.payload);
    if (resp.status) {
      yield put(setBusinessOwnershipType(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setBusinessOwnershipType({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* CompanyLogoUpload(action) {
  try {
    const resp = yield call(ApiClient.postFormData, action.url = ApiPath.UserApiPath.ATTACHMENT_UPLOAD, action.payload = action.payload);
    if (resp.status) {
      yield put(setCompanyLogoUpload(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setCompanyLogoUpload({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* industryGroups(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.UserApiPath.INDUSTRY_GROUPS, action.payload = action.payload);
    if (resp.status) {
      yield put(setGetAllIndustryGroups(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetAllIndustryGroups({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* industrySubGroups(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.UserApiPath.INDUSTRY_SUB_GROUPS}${action.payload.id}`);
    if (resp.status) {
      yield put(setGetAllIndustrySubGroups(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetAllIndustrySubGroups({}));
    yield put(onErrorStopLoad())

    toast.error(e.response.data.msg);
  }
}

function* DiverseSupplierCertification(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.UserApiPath.DIVERSE_SUPPLIER_CERTIFICATION, action.payload = action.payload);
    if (resp.status) {
      yield put(setExistingCertification(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setExistingCertification({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getUsersList(action) {
  var dataToSend = { ...action.payload }
  delete dataToSend.cb
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.UserApiPath.ALL_USERS, action.params = { params: dataToSend });
    if (resp.status) {
      yield put(setGetUsersList(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetUsersList({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getSmallUsers(action) {
  var dataToSend = { ...action.payload }
  delete dataToSend.cb
  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.UserApiPath.SMALL_USERS, action.params = { params: dataToSend });
    if (resp.status) {
      yield put(setGetUsersList(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetUsersList({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getPlans(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.UserApiPath.GET_PLANS}?type=1`, action.payload = action.payload);
    if (resp.status) {
      yield put(setGetPlans(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetPlans({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userAdd(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.UserApiPath.CREATE_USER, action.payload = action.payload);
    if (resp.status) {
      yield put(setUserAdd(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserAdd({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userView(action) {
  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.UserApiPath.GET_USER}/${action.payload.id}`);
    if (resp.status) {
      yield put(setUserView(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUserView({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}
// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* UpdateEnterprise(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.UserApiPath.UPDATE_USER}/${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setUpdateEnterprise(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUpdateEnterprise({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}
function* activeInactiveSmallUsers(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.UserApiPath.ACTIVE_INACTIVE_SMALL_USER}/${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setUpdateEnterprise(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUpdateEnterprise({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}
function* UpdateSmallUsers(action) {
  try {
    const resp = yield call(ApiClient.patch, action.url = `${ApiPath.UserApiPath.EDIT_SMALL_USER}/${action.payload.id}`, action.payload = action.payload);
    if (resp.status) {
      yield put(setUpdateEnterprise(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setUpdateEnterprise({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* deleteSmallUser(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.UserApiPath.DELETE_USER}/${action.payload.userId}`)
    if (resp.status) {
      yield call(action.payload.cb, action.res = "resp")
    }
  } catch (error) {
    yield put(onErrorStopLoad())
    toast.error(error.response.data.msg);
  }
}

function* userSaga() {
  yield all([
    takeLatest('users/diverseSupplierDescription', DiverseSupplierDescription),
    takeLatest('users/businessOwnershipType', ownershipType),
    takeLatest('users/companyLogoUpload', CompanyLogoUpload),
    takeLatest('users/getAllIndustryGroups', industryGroups),
    takeLatest('users/getAllIndustrySubGroups', industrySubGroups),
    takeLatest('users/existingCertification', DiverseSupplierCertification),
    takeLatest('users/userAdd', userAdd),
    takeLatest('users/userView', userView),
    takeLatest('users/updateEnterprise', UpdateEnterprise),
    takeLatest('users/getPlans', getPlans),
    takeLatest('users/getUsersList', getUsersList),
    takeLatest('users/getSmallUsers', getSmallUsers),
    takeLatest('users/activeInactiveSmallUsers', activeInactiveSmallUsers),
    takeLatest('users/UpdateSmallUsers', UpdateSmallUsers),
    takeLatest('users/deleteSmallUser', deleteSmallUser),
  ])
}

export default userSaga;