import { Route, Routes } from "react-router-dom";
import * as Containers from "../app/containers";
import * as Layouts from "../app/layouts"
import PrivateCheck from "../app/layouts/PrivateCheck";
import EnterpriseUsers from "../app/containers/enterpriseUsers";
import AddEnterpriseUser from "../app/containers/addEnterpriseUser";
import EditEnterpriseUser from "../app/containers/editEnterpriseUser";
import SmallUsers from "../app/containers/smallUsers";
import EditSmallUser from "../app/containers/smallUsers/editSmallUser";

const Router = () => {

    return (
        <>
            <Routes>  
                <Route element={<Layouts.MainLayout />}> 
                    {/* <Route path="/" element={<Containers.Login />} /> */}
                    <Route path="/aboutUs" element={<Containers.AboutUs />} />
                    <Route path="/termsCondition" element={<Containers.TermsCondition />} />
                    <Route path="/privacyPolicy" element={<Containers.PrivacyPolicy />} />
                    <Route path="/contactUs" element={<Containers.ContactUs />} />
                    <Route path="/frequentQuestion" element={<Containers.FrequentQuestion />} />
                </Route>

                <Route element={<Layouts.AuthLayout />}>
                    <Route element={<PrivateCheck auth={false} />}>
                        <Route path="/" element={<Containers.Login />} />
                        <Route path="/forgetPassword" element={<Containers.ForgetPassword />} />
                        <Route path="/forgetPasswordVerify" element={<Containers.ForgetPasswordVerification />} />
                        <Route path="/resetPassword" element={<Containers.ResetPassword />} />
                    </Route>

                    <Route element={<PrivateCheck auth={true} />}>
                        <Route path="/dashboard" element={<Containers.AccountDashboard />} />
                        <Route path="/support" element={<Containers.Support />} />
                        <Route path="/help-desk" element={<Containers.HelpDesk />} />
                        <Route path="/post-artical" element={<Containers.PostArtical />} />
                        <Route path="/edit-article/:id" element={<Containers.EditArticle />} />
                        <Route path="/users" element={<EnterpriseUsers />} />
                        <Route path="/small-users" element={<SmallUsers />} />
                        <Route path="/add-user" element={<AddEnterpriseUser />} />
                        <Route path="/edit-user/:id" element={<EditEnterpriseUser />} />
                        <Route path="/edit-small-user/:id" element={<EditSmallUser />} />
                    </Route>
                </Route>
            </Routes>

        </>
    );
}

export default Router;